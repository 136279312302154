import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { useDisclosure } from '@mantine/hooks';
import { HeadPage } from '../../componants';
import { AddCategory, AddCoupon, DeleteCategory, DeleteCoupon, ShowOrdersCoupon, UpdateCategory, UpdateCoupon } from '../../componants/Modal';
import { CouponsTabel } from '../../componants/Tables';
import Cookies from 'universal-cookie';
import { useCoupons } from '../../api';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
const cookies = new Cookies(null, { path: '/' });

export function Coupons () {
    const { t } = useTranslation();
    const [coupons, setCoupons] = useState<any[]>([]);
    const [selectedData, setSelectedData] = useState(null);
    const [openedAddModal, { open: openAddModal, close: closeAddModal }] = useDisclosure(false);
    const [openedEditModal, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
    const [openedDeleteModal, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
    const [openedShowModal, { open: openShowModal, close: closeShowModal }] = useDisclosure(false);
    const {loading, error, data: dataCoupons, refetch} = useCoupons()
    const [role, setRole] = useState("");
    // const theme = useMantineTheme();

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataCoupons.length >= 0) {
            setCoupons(dataCoupons)
        }
    }, [dataCoupons])

    if (process.env.REACT_APP_PAGE_COUPON_DISPLAY !== "true") {
        return (<></>)
    }

    if (!["admin"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={t('couponsPage.name')}
                links={[
                    { title: t('couponsPage.links.link01'), href: '/dashboard' },
                    { title: t('couponsPage.links.link02'), href: '' }
                ]}
                labelCreate={t('couponsPage.labelCreate')}
                labelExport={t('couponsPage.labelExport')}
                onCreate={openAddModal}
                onExport={() => console.log()}
                hiddenExport={true}
            />

            <CouponsTabel
                data={coupons}
                setSelectedData={setSelectedData}
                setOpenEditModal={openEditModal}
                setOpenDeleteModal={openDeleteModal}
                setOpenShowModal={openShowModal}

                loading={loading}
            />

            <AddCoupon title={t('couponsPage.addCoupon')} opened={openedAddModal} refetchData={refetch} onClose={closeAddModal} />
            <UpdateCoupon title={t('couponsPage.updateCoupon')} data={selectedData} refetchData={refetch} opened={openedEditModal} onClose={closeEditModal} />
            <DeleteCoupon data={selectedData} refetchData={refetch} opened={openedDeleteModal} onClose={closeDeleteModal} />
            <ShowOrdersCoupon data={selectedData} refetchData={refetch} opened={openedShowModal} onClose={closeShowModal} />
        </>
    );
}