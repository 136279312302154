import {Alert, Box, Button, Grid, Group, Text} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useOrdersCoupon } from "../../api";
import { sortedData } from "../../lib/sort";
import { OrdersCouponTabel } from "../Tables";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const ShowOrdersCoupon = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);
    const [meta, setMeta] = useState<any[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [sort, setSort] = useState<number>(-1);

    const {loading, error, data: dataOrders, fetchData} = useOrdersCoupon()
    
    useEffect(() => {
        if (props.opened) {
            fetchData({limit, page: pageNumber, sort, coupon: data})
        }
    }, [props.opened])
    
    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders?.data)
            setOrdersSorted(dataOrders?.data)
            setMeta(dataOrders?.meta)
            setPageNumber(dataOrders?.meta?.current_page)
        }
    }, [dataOrders])

    useEffect(() => {
        if (orders && orders?.length >= 0) {
            const newData = sortedData(orders)
            setOrdersSorted(newData)
        }
    }, [orders])

    const closeModal = () => {
        props.onClose();
    };

    return (
        <Modal {...props} onClose={closeModal} size="90%" footer={<></>} closeOnClickOutside={true}>
            <Box style={({ colors }) => ({padding: 0})}>
                <OrdersCouponTabel
                    data={ordersSorted}
                    loading={loading}
                    meta={meta}
                    page={pageNumber}
                    setPage={setPageNumber}
                    limit={limit}
                    setLimit={setLimit}
                />
            </Box>
        </Modal>
    );
};