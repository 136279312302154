import { RichTextEditor, Link, RichTextEditorProps } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Color } from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import Dropcursor from '@tiptap/extension-dropcursor';

import { IconColorPicker, IconFileUpload, IconPhoto } from '@tabler/icons-react';
import TextStyle from '@tiptap/extension-text-style';
import { Dispatch, FocusEventHandler, SetStateAction, useEffect, useState } from 'react';
import { ActionIcon, FileButton, Text } from '@mantine/core';
import { client } from '../../lib/axiosClient';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });
interface Props {
    content: string;
    setContent: Dispatch<SetStateAction<string>>
}

export function RichText({content, setContent, ...props}: Props) {
    let editor = useEditor({
        extensions: [
            StarterKit, Underline, Link, Superscript, SubScript, Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }), TextStyle, Color,
            Image, Dropcursor.configure({ class: 'dropcursor' }),
        ],
        content,
        onUpdate: ({editor}) => setContent(editor.getHTML()),
    });

    useEffect(() => {
        if (editor && editor !== null) {
            editor.view.dom.addEventListener('drop', async (event) => {
                event.preventDefault();
                console.log('drop');
                
                const files = event.dataTransfer?.files;
                if (files?.length) {
                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        const imageUrl = await uploadImage(file);
                        editor?.chain()?.focus()?.setImage({ src: imageUrl })?.run();
                    }
                }
            });
        }
    }, [editor])

    const addImageToEditor = async (file: File | null) => {
        if (file) {
            const imageUrl = await uploadImage(file);
            editor?.chain()?.focus()?.setImage({ src: imageUrl })?.run();
        }
    }

    const uploadImage = async (file: File): Promise<string> => {
        const formData = new FormData();
        formData.append('image', file);
      
        const response = await client.post("/products/upload-image", formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })

        const data = await response.data;
        return data?.url;
    };

    return (
        <RichTextEditor {...props} editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Highlight />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                    <RichTextEditor.Subscript />
                    <RichTextEditor.Superscript />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Undo />
                    <RichTextEditor.Redo />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ColorPicker
                    colors={[
                        '#25262b',
                        '#868e96',
                        '#fa5252',
                        '#e64980',
                        '#be4bdb',
                        '#7950f2',
                        '#4c6ef5',
                        '#228be6',
                        '#15aabf',
                        '#12b886',
                        '#40c057',
                        '#82c91e',
                        '#fab005',
                        '#fd7e14',
                    ]}
                />
                
                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Color color="#F03E3E" />
                    <RichTextEditor.Color color="#7048E8" />
                    <RichTextEditor.Color color="#1098AD" />
                    <RichTextEditor.Color color="#37B24D" />
                    <RichTextEditor.Color color="#F59F00" />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.UnsetColor />

                <RichTextEditor.ControlsGroup>
                    <FileButton onChange={addImageToEditor} accept="image/png,image/jpeg">
                        {(props) => (
                            <ActionIcon {...props} variant='outline' color='gray.4' size={26}>
                                <IconPhoto size={16} color='#000' />
                            </ActionIcon>
                        )}
                    </FileButton>
                </RichTextEditor.ControlsGroup>


            </RichTextEditor.Toolbar>

            <RichTextEditor.Content mih={150} />
        </RichTextEditor>
    );
}